<template lang="pug">
.select
  .select__default
    .text(
      :class="[...takeSelectedClass]"
      v-if="options"
      @click="doSetOptionsVisibility"
    )
      span(
        selected
        disabled
      ) {{selected}}

    .icon(
      @click="doSetOptionsVisibility"
    )
      FontAwesomeIcon(
        v-if="options"
        :icon="['fas', 'chevron-down']"
      )

  .select__options(
    v-show="showOptions"
  )
    .option(
      v-for="option in options"
      v-if="option.text && option.value !== null && option.value !== undefined"
      value="option.value"
      @click="doSetSelected(option)"
    )
      span.text {{option.text}}
</select>
</template>

<script>

export default {
  name: 'Check',
  props: {
    defaultText: {
      type: String,
      default: 'Selecione'
    },
    type: {
      type: String,
      default: 'check',
      validator: value =>
        ['check', 'drop'].includes(value)
    },
    options: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    showOptions: false,
    selected: ''
  }),
  mounted () {
    this.selected = this.defaultText
  },
  computed: {
    takeSelectedClass () {
      const block = 'text'
      const classList = []
      if (this.selected !== this.defaultText) {
        classList.push(`${block}--selected`)
      }
      return classList
    }
  },
  methods: {
    doSetSelected (value) {
      this.selected = value.text
      this.$emit('emit', value)
      this.doSetOptionsVisibility()
    },
    doSetOptionsVisibility () {
      this.showOptions = !this.showOptions
    }
  }
}
</script>

<style lang="sass" scoped>
.select
  display: flex
  justify-content: center
  flex-direction: column
  &__default
    display: flex
    justify-content: center
    cursor: pointer
    flex-direction: row
    .text
      min-width: 48px
      color: #C4C4C4
      border: 1px solid #000000
      font-size: 16px
      line-height: 30px
      letter-spacing: 0em
      height: 40px
      padding: 0 8px
      border-radius: 4px 0 0 4px
      display: flex
      align-items: center
      width: 100%
      &--selected
        color: black

    .icon
      display: flex
      justify-content: center
      align-items: center
      width: 32px
      border: 1px solid #000000
      border-left: none
      height: 40px
      font-size: 16px
      border-radius: 0 4px 4px 0

  &__options
    display: flex
    flex-direction: column
    width: 100%
    overflow-y: auto
    z-index: 1
    .option
      color: #C4C4C4
      cursor: pointer
      height: 32px
      display: flex
      align-items: center
      justify-content: flex-start
      border: 1px solid #000000
      border-radius: 5px
      padding: 0
      margin: 2px 0
      .text
        margin: 0 8px
        font-size: 16px
        line-height: 30px
        letter-spacing: 0em

</style>
